import ChildGridActions from '../ChildGridActions/ChildGridActions';
import React, { useState } from 'react';
import { useGetChildrenForRoster } from '../../api/child_api';
import { ChildForRoster, Home } from '../../typings/api-types';
import { Domain } from '../../api/permissions_api';
import { ColDef, GridApi, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import GenericProtectedSearchPage from '../../shared/components/GenericProtectedSearchPage/GenericProtectedSearchPage';
import AlertIconsCell, {
  flattenAlertsToTextBlock,
  getGroupName,
  getIconWithTooltipFromAlertGroup,
} from '../../alert/AlertIconsCell/AlertIconsCell';
import _ from 'lodash';
import AlertIconsFilterOption from '../../alert/AlertIconsFilterOption/AlertIconsFilterOption';
import BooleanCell from '../../shared/grid/BooleanCell/BooleanCell';
import { SITUATION_STATUS_OPTIONS, TRACK_STATUS_OPTIONS } from '../ChildOverview/ChildOverview';
import {
  CHURCH_STATUS_OPTIONS,
  CITIZENSHIP_STATUS_OPTIONS,
  JOB_STATUS_OPTIONS,
  SCHOLARSHIP_TIER_OPTIONS,
} from '../ChildTransition/ChildTransition';
import { DEFAULT_DATE_FILTER_PARAMS, isoDateToCommonFormat } from '../../shared/helpers/dateHelper';
import { ICellRendererParams } from 'ag-grid-community';
import { findOptionLabelFromValue } from '../../shared/helpers/optionsHelper';
import { getClassDisplay, getShortenedClassDisplay } from '../../shared/helpers/classLevelHelper';
import ChildRosterFilterRow from '../ChildRosterFilterRow/ChildRosterFilterRow';
import { GenericOption } from '../../shared/formik/FormikSelect/FormikSelect';
import i18n from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetHomes } from '../../api/home_api';

type ChildRosterProps = {};

const ALERT_EVENT_TYPES = [
  {
    code: 'BIRTHDAY',
    label: 'Birthday',
  },
  {
    // This alert is send to OM but not created as of 2024-03-22
    // This is because I haven't wrote code to auto-resolve it after a few weeks
    code: 'SITUATION_STATUS_CHANGE',
    label: 'Situation Status Changed Recently',
  },
  {
    // This alert is send to OM but not created as of 2024-03-22
    // This is because I haven't wrote code to auto-resolve it after a few weeks
    code: 'TRACK_CHANGE',
    label: 'Track Status Changed Recently',
  },
  {
    code: 'NUTRITION_ALERT',
    label: 'Child is Malnourished',
  },
  {
    code: 'LATE_VACCINATIONS',
    label: 'A Vaccination is Late',
  },
  {
    code: 'EMOTIONAL_CHECK_IN_ALERT',
    label: 'A Mental Health Check-in had an alert',
  },
  {
    code: 'EMOTIONAL_CHECK_IN_DISTRESSED_ALERT',
    label: 'Child is Distressed',
  },
  {
    code: 'BECK_ALERT',
    label: 'A BECK assessment had a risk',
  },
  {
    code: 'RSCA_ALERT',
    label: 'A Resiliency assessment had a risk',
  },
  {
    code: 'GRADE_ALERT',
    label: 'A Grade Report was poor',
  },
  {
    code: 'CHILD_TRAINING_ALERT',
    label: 'Child needs Child Trainings(s)',
  },
  {
    code: 'LIFE_SKILL_ALERT',
    label: 'Child needs Life Skills(s)',
  },
  {
    code: 'BLANK_CHURCH_STATUS_ALERT',
    label: 'Church status is blank',
  },
  {
    code: 'BLANK_JOB_STATUS_ALERT',
    label: 'Job status is blank',
  },
  {
    code: 'BLANK_CITIZENSHIP_STATUS_ALERT',
    label: 'Citizenship status is blank',
  },
  {
    code: 'BLANK_SCHOLARSHIP_TIER_ALERT',
    label: 'Scholarship tier is blank',
  },
  {
    code: 'CHURCH_STATUS_ALERT',
    label: 'Child is not going to church',
  },
  {
    code: 'JOB_STATUS_ALERT',
    label: 'Child does not have a job',
  },
  {
    code: 'CITIZENSHIP_STATUS_ALERT',
    label: 'Child does not have citizenship',
  },
  {
    code: 'SPONSORSHIP_ALERT',
    label: 'Child needs sponsorship',
  },
  // 'Unknown'
];

const getColumns = (onDelete: (objectToDelete: ChildForRoster) => Promise<any>): ColDef<ChildForRoster>[] => {
  const t = i18n.t;
  const alertColumns = ALERT_EVENT_TYPES.map((eventType) => {
    return {
      headerName: t('child.search.ALERTS') + ' - ' + eventType.label,
      field: 'active_alerts',
      valueGetter: (params: ValueGetterParams<ChildForRoster>) => {
        const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
        let alertsOfThisType = groupedAlerts[eventType.code];
        if (alertsOfThisType) {
          const textBlock = flattenAlertsToTextBlock(alertsOfThisType);
          return textBlock;
        } else {
          return '';
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
        let alertsOfThisType = groupedAlerts[eventType.code];
        if (alertsOfThisType) {
          return getIconWithTooltipFromAlertGroup(params.data.id, eventType.code, alertsOfThisType);
        } else {
          return <div />;
        }
      },
      filterParams: {
        cellRenderer: AlertIconsFilterOption,
        valueGetter: (params: ValueGetterParams<ChildForRoster>) => {
          const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
          let alertsOfThisType = groupedAlerts[eventType.code];
          if (alertsOfThisType) {
            const textBlock = flattenAlertsToTextBlock(alertsOfThisType);
            return textBlock;
          } else {
            return '';
          }
        },
      },
      width: 20,
      hide: true,
    };
  });

  return [
    {
      field: 'name_with_nickname',
      headerName: t('common.CHILD_NAME'),
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
    {
      field: 'public_id',
      headerName: t('common.PUBLIC_ID'),
      width: 90,
      hide: true,
    },
    {
      field: 'home_name',
      headerName: t('common.HOME_NAME'),
      width: 90,
    },
    {
      field: 'gender',
      headerName: t('common.GENDER'),
      width: 90,
    },

    // Alerts
    {
      field: 'active_alerts',
      headerName: t('child.search.ALERTS'),
      cellRenderer: AlertIconsCell,
      valueGetter: (params) => {
        const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
        return Object.keys(groupedAlerts).sort();
      },
      valueFormatter: (params) => {
        const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
        const groups = Object.keys(groupedAlerts)
          .sort()
          .map((code) => {
            let foundType = ALERT_EVENT_TYPES.find((eventType) => code === eventType.code);
            if (foundType) {
              return foundType;
            } else {
              return {
                code: code,
                label: 'UNKNOWN ALERT - ' + code,
              };
            }
          });
        const humanReadable = groups.map((group) => group.label).join(', ');
        return humanReadable;
      },
      filterParams: {
        cellRenderer: AlertIconsFilterOption,
        // valueGetter: (params: ValueGetterParams<ChildForRoster>) => {
        //   const groupedAlerts = _.groupBy(params.data?.active_alerts, getGroupName);
        //   const groups = Object.keys(groupedAlerts)
        //     .sort()
        //     .map((code) => {
        //       let foundType = ALERT_EVENT_TYPES.find((eventType) => code === eventType.code);
        //       if (foundType) {
        //         return foundType;
        //       } else {
        //         return {
        //           code: code,
        //           label: 'UNKNOWN ALERT - ' + code,
        //         };
        //       }
        //     });
        //   const humanReadable = groups.map((group) => group.label).join('4 ');
        //   return humanReadable;
        // },
      },
      width: 150,
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
    // pull the columns out of their own array and put them in the middle of this one
    ...alertColumns,

    {
      field: 'situation_status',
      headerName: t('child.search.SITUATION_STATUS'),
      width: 150,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, SITUATION_STATUS_OPTIONS);
      },
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return findOptionLabelFromValue(params.value, SITUATION_STATUS_OPTIONS);
        }
      }
    },
    {
      field: 'track_status',
      headerName: t('child.search.TRACK_STATUS'),
      width: 150,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, TRACK_STATUS_OPTIONS);
      },
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return findOptionLabelFromValue(params.value, TRACK_STATUS_OPTIONS);
        }
      }
    },
    {
      field: 'date_of_birth',
      headerName: t('child.search.DATE_OF_BIRTH'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
    },
    {
      field: 'age',
      headerName: t('child.search.AGE'),
      width: 110,
    },
    {
      field: 'current_class',
      headerName: t('child.search.CURRENT_CLASS'),
      width: 110,
      valueFormatter: (params) => {
        return getShortenedClassDisplay(params.value);
      },
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return getShortenedClassDisplay(params.value) + ": " + getClassDisplay(params.value);
        }
      }
    },

    // Fields that are hidden by default
    {
      field: 'is_in_home',
      headerName: t('child.search.IS_IN_HOME'),
      width: 110,
      cellRenderer: BooleanCell,
      hide: true,
    },
    {
      field: 'first_name',
      headerName: t('child.search.FIRST_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'middle_name',
      headerName: t('child.search.MIDDLE_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'last_name',
      headerName: t('child.search.LAST_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'nickname',
      headerName: t('child.search.NICKNAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'situation_status_last_updated',
      headerName: t('child.search.SITUATION_STATUS_LAST_UPDATED'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'track_status_last_updated',
      headerName: t('child.search.TRACK_STATUS_LAST_UPDATED'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'is_bio_child',
      headerName: t('child.search.IS_BIO_CHILD'),
      width: 110,
      cellRenderer: BooleanCell,
      hide: true,
    },
    {
      field: 'intake_date',
      headerName: t('child.search.INTAKE_DATE'),
      width: 110,
      hide: true,
    },
    {
      field: 'intake_reference',
      headerName: t('child.search.INTAKE_REFERENCE'),
      width: 110,
      hide: true,
    },
    {
      field: 'intake_class',
      headerName: t('child.search.INTAKE_CLASS'),
      width: 110,
      hide: true,
    },
    {
      field: 'personal_phone',
      headerName: t('child.search.PERSONAL_PHONE'),
      width: 110,
      hide: true,
    },
    {
      field: 'personal_email',
      headerName: t('child.search.PERSONAL_EMAIL'),
      width: 110,
      hide: true,
    },
    {
      field: 'home_departure_date',
      headerName: t('child.search.HOME_DEPARTURE_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'reason_they_left_home',
      headerName: t('child.search.REASON_THEY_LEFT_HOME'),
      width: 110,
      hide: true,
    },
    {
      field: 'home_exit_survey_where_they_are_now',
      headerName: t('child.search.HOME_EXIT_SURVEY_WHERE_ARE_THEY_NOW'),
      width: 110,
      hide: true,
    },
    {
      field: 'is_financial_support_finished',
      headerName: t('child.search.IS_FINANCIAL_SUPPORT_FINISHED'),
      width: 110,
      cellRenderer: BooleanCell,
      hide: true,
    },
    {
      field: 'program_departure_date',
      headerName: t('child.search.PROGRAM_DEPARTURE_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'plus_two_or_vocational_school_name',
      headerName: t('child.search.PLUS_TWO_SCHOOL_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'plus_two_or_vocational_school_start_date',
      headerName: t('child.search.PLUS_TWO_CITY'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_bachelors_school_name',
      headerName: t('child.search.BACHELORS_SCHOOL_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_bachelors_school_start_date',
      headerName: t('child.search.BACHELORS_START_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'higher_ed_bachelors_city',
      headerName: t('child.search.BACHELORS_CITY'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_bachelors_country',
      headerName: t('child.search.BACHELORS_COUNTRY'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_bachelors_graduated_date',
      headerName: t('child.search.BACHELORS_GRADUATED_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'higher_ed_masters_school_name',
      headerName: t('child.search.MASTERS_SCHOOL_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_masters_school_start_date',
      headerName: t('child.search.MASTERS_START_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'higher_ed_masters_city',
      headerName: t('child.search.MASTERS_CITY'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_masters_country',
      headerName: t('child.search.MASTERS_COUNTRY'),
      width: 110,
      hide: true,
    },
    {
      field: 'higher_ed_masters_graduated_date',
      headerName: t('child.search.MASTERS_GRADUATED_DATE'),
      width: 110,
      valueFormatter: (params: any) => {
        return isoDateToCommonFormat(params.value) || '';
      },
      filter: 'agDateColumnFilter',
      filterParams: DEFAULT_DATE_FILTER_PARAMS,
      hide: true,
    },
    {
      field: 'job_status',
      headerName: t('child.search.JOB_STATUS'),
      width: 150,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, JOB_STATUS_OPTIONS);
      },
      hide: true,
    },
    {
      field: 'church_status',
      headerName: t('child.search.CHURCH_STATUS'),
      width: 150,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, CHURCH_STATUS_OPTIONS);
      },
      hide: true,
    },
    {
      field: 'citizenship_status',
      headerName: t('child.search.CITIZENSHIP_STATUS'),
      width: 110,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, CITIZENSHIP_STATUS_OPTIONS);
      },
      hide: true,
    },
    // job_name_of_employer / job_title likely to get deleted
    // life_skills_status likely to get deleted
    {
      field: 'scholarship_tier',
      headerName: t('child.search.SCHOLARSHIP_TIER'),
      width: 110,
      valueFormatter: (params) => {
        return findOptionLabelFromValue(params.value, SCHOLARSHIP_TIER_OPTIONS);
      },
      hide: true,
    },
    {
      field: 'can_be_sponsored',
      headerName: t('child.search.CAN_BE_SPONSORED'),
      width: 110,
      cellRenderer: BooleanCell,
      hide: true,
    },
    {
      headerName: t('child.search.SPONSORS'),
      field: 'sponsorships',
      valueGetter: (params) => {
        if (!params.data || !params.data.sponsorships) {
          return '';
        }
        const sponsorships = params.data.sponsorships;
        return sponsorships.map((sponsorship) => sponsorship.sponsor_name).join(', ');
      },
      width: 110,
      hide: true,
    },
    {
      field: 'current_school_name',
      headerName: t('child.search.CURRENT_SCHOOL_NAME'),
      width: 110,
      hide: true,
    },
    {
      field: 'is_in_program',
      headerName: t('child.search.IS_IN_PROGRAM'),
      width: 110,
      cellRenderer: BooleanCell,
    },
    {
      field: 'is_left_early',
      headerName: t('child.search.IS_LEFT_EARLY'),
      width: 110,
      cellRenderer: BooleanCell,
      hide: true,
    },

    // Actions should come last
    {
      headerName: t('common.ACTIONS'),
      width: 90,
      cellRenderer: ChildGridActions,
      cellRendererParams: {
        pageUrl: '/child',
        onDelete: (id: number) => alert('Deleting child not allowed at this time ' + id),
      },
      pinned: 'right',
      // Can't disable column
      suppressColumnsToolPanel: true,
    },
  ];
};

function setIsInProgramFilter(gridApi: GridApi<any>, booleanValue: boolean | null | undefined) {
  const stringValue = booleanValue?.toString();
  if (stringValue === null || stringValue === undefined) {
    gridApi.destroyFilter('is_in_program');
  } else {
    gridApi.getFilterInstance('is_in_program', (filterInstance) => {
      if (filterInstance === null) {
        throw Error('No filter instance for is_in_program');
      }

      filterInstance.setModel({
        filterType: 'set',
        values: [stringValue],
      });

      // Tell grid to run filter operation again
      gridApi!.onFilterChanged();
    });
  }
}

function setHomeFilter(gridApi: GridApi<any>, filterHomeNames: string[] | null) {
  if (!filterHomeNames) {
    return;
  }

  gridApi.getFilterInstance('home_name', (filterInstance) => {
    if (filterInstance === null) {
      throw Error('No filter instance for home_name');
    }

    filterInstance.setModel({
      filterType: 'set',
      values: filterHomeNames,
    });

    // Tell grid to run filter operation again
    gridApi!.onFilterChanged();
  });
}

const ChildRosterPage = (props: ChildRosterProps) => {
  const [isInProgram, setIsInProgram] = useState<boolean | undefined>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const filterHomeIdsString = searchParams.get('homeIds');

  const { data: homeData } = useGetHomes();

  // Only null when no search parameter
  let filterHomeIds: number[] | null = null;
  if (filterHomeIdsString) {
    const idStrings = filterHomeIdsString.split(',');
    filterHomeIds = idStrings.map((idString) => {
      return Number(idString);
    });
  }

  const setFilterHomeIds = (ids: number[] | null) => {
    setSearchParams((prev) => {
      if (!ids) {
        prev.delete('homeIds');
      } else {
        const homeIdsString = ids.join(',');
        prev.set('homeIds', homeIdsString);
      }
      return prev;
    });
  };

  // null until homeData is loaded
  let filterHomeNames: string[] | null = null;
  if (filterHomeIds && homeData) {
    filterHomeNames = homeData
      .filter((maybeFilterHome: Home) => {
        return filterHomeIds!.includes(maybeFilterHome.id!);
      })
      .map((filterHome) => {
        return filterHome.name;
      });
  }

  const onInProgramChanged = (newOption: GenericOption<boolean> | null, gridApi: GridApi | undefined) => {
    if (!gridApi) {
      return;
    }
    setIsInProgram(newOption?.value);
    setIsInProgramFilter(gridApi, newOption?.value);
  };

  return (
    <GenericProtectedSearchPage
      customFilterRow={(grid) => (
        <ChildRosterFilterRow
          filterHomeIds={filterHomeIds}
          setFilterHomeIds={setFilterHomeIds}
          isInProgramValue={isInProgram}
          grid={grid}
          onInProgramChanged={onInProgramChanged}
        />
      )}
      customOnFirstDataRendered={(event) => {
        setIsInProgramFilter(event.api, isInProgram);
        setHomeFilter(event.api, filterHomeNames);
      }}
      savedPreferencesKey={'child-roster'}
      getColumns={getColumns}
      permissionDomain={Domain.CHILD}
      translatePrefix={'child.search.'}
      useGetListApiHook={useGetChildrenForRoster}
      createForChildPageUrl={'/child/intake'}
      partialDataMode={true}
      partialDataAlwaysLoadFields={['id', 'age', 'current_class', 'is_in_program', 'home_name']}
    />
  );
};

export default ChildRosterPage;
export { ALERT_EVENT_TYPES };
